<template>
  <el-aside style="height: 100vh;background-color:#fff;border-right: solid 1px #ddd;" :width="minWidth">
    <el-menu
      @open="handleOpen"
      @close="handleClose"
      background-color="#ffffff"
      text-color="#666"
      active-text-color="#409EFF"
      :default-active="$route.path"
      router="router"
      :collapse="iscollapse"
      style="border-right: none;"
    > 
      <i class="collapse_icon el-icon-arrow-right" @click="handleCollapse"></i>

      <el-menu-item index="/kintone/Top" >
        <i class="el-icon-s-home menu_icon" style="color: #409EFF;font-weight: 300;"></i>
        <span slot="title" class="menu_text">トップ</span>
      </el-menu-item>

      <el-submenu index="form">
        <template slot="title">
          <i class="el-icon-s-grid" style="color: #409EFF;font-weight: 300;"></i>
          <span class="menu_text">OCR</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/kintone/DocList" class="sub_menu_text">ロボット管理</el-menu-item>
          <el-menu-item index="/kintone/TaskList" class="sub_menu_text">実行履歴</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/kintone/PDFList" >
        <i class="el-icon-s-order" style="color: #409EFF;font-weight: 300;"></i>
        <span slot="title" class="menu_text">帳票</span>
      </el-menu-item>

      <el-submenu index="data">
        <template slot="title">
          <i class="el-icon-s-data menu_icon" style="color: #409EFF;font-weight: 300;"></i>
          <span class="menu_text">データ</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/kintone/Maintenance" class="sub_menu_text">アンケート</el-menu-item>
          <el-menu-item index="/kintone/Maintenance" class="sub_menu_text">定時タスク</el-menu-item>
          <el-menu-item index="/kintone/Maintenance" class="sub_menu_text">データ集計</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!--el-submenu index="data">
        <template slot="title">
          <i class="el-icon-s-opportunity" style="color: #409EFF;font-weight: 300;"></i>
          <span class="menu_text">IoT</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/kintone/Maintenance" class="sub_menu_text">Pay</el-menu-item>
        </el-menu-item-group>
      </el-submenu-->

      <el-menu-item index="/kintone/Account" >
        <i class="el-icon-s-tools menu_icon" style="color: #409EFF;font-weight: 300;"></i>
        <span slot="title" class="menu_text">アカウント</span>
      </el-menu-item>

      <el-menu-item index="/account/loginOut" >
        <i class="el-icon-error menu_icon" style="color: #409EFF;font-weight: 300;"></i>
        <span slot="title" class="menu_text">ログアウト</span>
      </el-menu-item>
      
    </el-menu>  
  </el-aside>
</template>

<script>
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "CommonLeftMenu",
  data: () => {
    return {
      ROUTER_PATH: ROUTER_PATH,
      iscollapse: false,
      minWidth: "220px"
    };
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
   methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      handleCollapse(){
        this.iscollapse = !this.iscollapse;
        if(!this.iscollapse){
          this.minWidth = "180px"
        } else {
          this.minWidth = "70px"
        }
      }
   }
};
</script>

<style scoped>
.collapse_icon {
  margin-top: 24px;
  margin-left: 20px; 
  margin-bottom: 20px;
  font-size: 22px;
}
.menu_icon {
  font-size: 22px;
  color: #409EFF;
}
.menu_text {
  font-size: 16px;
}
.sub_menu_text {
  font-size: 14px;
}
</style>
