<template>
  <el-container style="height: 100%;">
    <CommonLeftMenu/>
    <el-container>
      <div class="right-content">
        <router-view></router-view>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import CommonLeftMenu from "@/components/common/CommonLeftMenu.vue";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: { CommonLeftMenu},
  computed: {
    ...mapState(["accountInfo"])
  },
};
</script>
<style>
.right-content{
  overflow-y: scroll ; 
  overflow-x: hidden ; 
  flex:1;
}

</style>
